.form-tooltip {
  padding-left: 16px;
}

h3.credits-available {
  display: flex;
  align-items: center;
}

h3.credits-available > img {
  margin-left: 8px;
}

.btn-mint {
  background-color: #12f9a1 !important;
  width: 141.5px !important;
  height: 39.33px !important;
  padding: 11.17px 50.25px 11.17px 50.25px !important;
  border-radius: 22.33px !important;
  border: 1.4px solid #12f9a1 !important;
  color: #1a1a1a !important;
}
.btn-mint-outline {
  background-color: #1a1a1a !important;
  width: 141.5px !important;
  height: 39.33px !important;
  padding: 11.17px 50.25px 11.17px 50.25px !important;
  border-radius: 22.33px !important;
  border: 1.4px solid #12f9a1 !important;
  color: #fff !important;
}
.btn-auto {
  width: auto !important;
}
.disconnect-btn {
  width: auto !important;
  text-align: center;
  text-decoration: none;
  cursor: pointer !important;
}

.company-value {
  height: 34px !important;
  margin-right: 10px !important;
  margin-top: 8px !important;
  border: 1px solid #c4c4c4;
  border-radius: 22.33px !important;
}
.company-value-selected {
  height: 34px !important;
  margin-right: 10px !important;
  margin-top: 8px !important;
  background-color: #12f9a1 !important;
  border-radius: 22.33px !important;
  border: 1.4px solid #12f9a1 !important;
  color: #1a1a1a !important;
}
label {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20.71px !important;
  text-align: left !important;
}
div.fui-Field,
div.fui-Combobox {
  margin-top: 8px !important;
}
.fui-Combobox,
.fui-SpinButton {
  height: 41px !important;
}
.fui-Combobox,
.fui-SpinButton,
.fui-Textarea {
  border: 0.86px solid #d9d9d9 !important;
  background: #464646 !important;
}
.fui-Combobox::after,
.fui-SpinButton::after,
.fui-Textarea::after {
  border-bottom-color: #12f9a1 !important;
}

textarea {
  height: 136px !important;
}
