#login-page {
  background: rgb(41, 41, 41);
  background: linear-gradient(
    180deg,
    rgba(41, 41, 41, 1) 100%,
    rgba(75, 75, 75, 1) 100%
  );
  .ms-Grid {
    padding: 0 !important;
  }
  .ms-Grid-row {
    margin: 0 !important;
  }
  .container {
    padding: 0 !important;
  }
  .ms-Grid-col {
    padding-left: 48px !important;
  }

  .column-logo {
    margin-top: 56px;
  }

  h1 {
    font-size: 2.178rem;
    line-height: 2.729rem;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 0;
  }

  p {
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .column-first,
  .column-last {
    padding-right: 64px;
    position: relative;
    top: 50px;
  }
  .phone-bottom-left {
    position: absolute;
    top: 311px;
    left: -72px;
  }
  .phone-with-zuzo {
    position: absolute;
    left: -10px;
    top: -90px;
  }
  .phone-top-right {
    position: absolute;
    left: 194px;
    top: -136px;
  }
  .no-account {
    position: relative;
    top: 110px;
    padding-right: 24px;
  }
  .instruction {
    font-size: 1rem;
  }
  label {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 14px !important;
    text-align: left !important;
  }
  .mobile-field {
    min-width: 340px;
    height: 38px;
  }

  .dashboard-links {
    width: 86% !important;
    text-align: center;
    padding-bottom: 34px !important;
    text-decoration: none;
    cursor: pointer !important;
  }

  @media only screen and (max-width: 768px) {
    .column-logo {
      margin-top: 0;
    }
    .column-first,
    .column-last {
      padding-right: 0;
      top: 0;
    }
    .column-last {
      display: none;
    }
    .container {
      padding: 24px !important;
    }
    .ms-Grid-col {
      padding-left: 24px !important;
    }
    .columns {
      height: auto !important;
    }
    .no-account {
      padding-right: 24px;
      position: relative;
      top: 16px;
    }
    .mobile-field {
      min-width: 300px;
    }
  }
}
