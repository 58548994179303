.no-sub-header {
  padding: 48px 0px 0px 64px;
}
.no-sub-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  text-align: center;
  padding: 0px 64px;
}
