.container {
  height: 100vh;
  padding: 48px 0px 0px 56px;
}
.logo {
  margin-bottom: 24px;
}
div,
p {
  line-height: 24px;
}
.grid-border-left {
  padding-left: 48;
  padding-right: 64;
  border-left: 4px solid #464646;
}
@media only screen and (max-width: 768px) {
  .container {
    padding-left: 32px;
  }
  .grid-border-left {
    border-left: 0;
    padding-left: 0;
  }
}
